import * as React from "react"
import { Link } from "gatsby"
import FourCols from "../components/FourCols"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import TwoCols from "../components/TwoCols"
import mainImage from "../images/main-image.png"
import heroPaint from "../images/hero-bg-paint.jpg"
import diamondPaint from "../images/blue-crumple-paint.png"
import paperCoin from "../images/paper-coin.png"
import roundTable from "../images/round-table.png"
import teamMember from "../images/team.png"
import kyle from "../images/KYLE.png"
import martin from "../images/MARTIN.png"
import monarch from "../images/MONARCH.jpg"
import nftdyor from "../images/NFTDYOR.png"
import flex from "../images/FLEX.png"
import rich from "../images/RICHARD.png"
import ekat from "../images/EKAT.jpg"
import dkong from "../images/DKONG.jpg"
import vikong from "../images/VIKONG.jpg"
import brad from "../images/BRAD.png"
import saantii from "../images/SAANTII.png"
import roadmap1 from "../images/roadmap1.png"
import roadmap2 from "../images/roadmap2.png"
import roadmap3 from "../images/roadmap3.png"
import roadmap4 from "../images/roadmap4.png"
import roadmap5 from "../images/roadmap5.png"
import diamondRound from "../images/diamond-round-table.png"
import ollie from "../images/OLLIE.png"
import ryan from "../images/RYAN.png"
import drake from "../images/DRAKE.png"
import katy from "../images/KATYA.jpg"
import evolve from "../images/evolve.png"
import metaverse from "../images/metaverse.png"
import metal from "../images/metal.gif"
import heroGif from "../images/hero-gif.gif"
import lightGreen from "../images/lightgreen-question.png"
import darkGreen from "../images/darkgreen-question.png"
import black from "../images/black-question.png"
import store from "../images/store.png"
import mint from "../images/mint.png"
import faqMan from "../images/faq.png"
import leftArrow from "../images/arrow-left.png"
import rightArrow from "../images/arrow-right.png"
import line1 from "../images/line1.png"
import line2 from "../images/line2.png"
import line3 from "../images/line3.png"
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"
import FAQs from "../components/FAQs"
import DiamondHands from "../components/DiamondHands"
const IndexPage = () => (
   <Layout>
    <Hero
      title="Become The Artist"
      text="We’re creating a Web3 gaming ecosystem to empower artists of all skills levels. PaperHands are your avatars in a simple game where one player draws what is indicated by the artist, while other players attempt to guess what is being drawn. Those who guess correctly are rewarded PPR digital rewards and in turn will have the chance to become the next “artist”."
      button="Mint Now"
      image={heroGif}
      alt="Paperhands"
      class="jumbo-section hero full-width  mobile-pt-13"/>
   <Container className="diamond-container mobile-mt-m10 mobile-pt-10 mobile-mt-m15 mobile-pb-5" style={{position:"relative", zIndex:"1"}} >
        <Row>
        <DiamondHands 
        image={roadmap1}/>
        </Row>
        </Container>
        <a name="roadmap" />
   <Container className="roadmap-container mobile-mt-m10 mt-m10 mobile-pt-7 mobile-pb-10" style={{position:"relative", zIndex:"2"}}>
        <Row>
          <Col xs={12} lg={6} className="text-container">
            <h2 className="title">
              The Roadmap
            </h2>
            <p>
            We are creators with one true end goal in mind: Building the ultimate light-hearted paint game that re-awakens our inner child to have fun with our old and new frens inside the NFT space.

            </p>
          </Col>

        </Row>
        <TwoCols 
        lineImg={line1}

        date="Stage One"
        title="Launch"
        text="PaperHands Mint, staking for $PPR rewards and PaperGames are live."
        image={roadmap2}
        alt="Roadmap"
        id=""
        class="jumbo-section jumbo-left medium-title mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt-3 swap"/>

        <TwoCols 
        lineImg={line3}
        date="Stage Two"
        title="DiamondHands Pass"
        text="Get upgraded ecosystem access and in-game abilities."
        image={diamondRound}
        floatingImg={leftArrow}
        alt="Roadmap"
        id=""
        name="roadmap"
        class="jumbo-section jumbo-left medium-title mt-m7 mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt3 "/>

      <TwoCols 
        lineImg={line2}
        date="Stage Three"
        title="Paper Store"
        text="The official PAPER STORE will contain physical and digital goods (NFTs, brushes, canvases, in-game skins, whitelists, etc.) that players can purchase with the $PPR token earned from playing our games and participating in community events."
        image={roadmap4}
        floatingImg={leftArrow}
        alt="Roadmap"
        id=""
        name="roadmap"
        class="jumbo-section jumbo-left medium-title mt-m7 mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt-3 swap"/>

      <TwoCols 
        lineImg={line2}
        date="Stage Four"
        title="Official $PPR Reward Launch"
        text="Our official $PPR reward is launched to become the basis of our in-game currency."
        image={paperCoin}
        floatingImg={rightArrow}
        alt="Roadmap"
        id=""
        name="roadmap"
        class="jumbo-section jumbo-left medium-title mt-m7 mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt3 "/>

      <TwoCols 
        lineImg={line3}
        date="Stage Five"
        title="Secret Announcement"
        text="Where would the fun be if you'd already know everything? But something’s telling me it may not be the worst idea to start stacking some $PPR…" 
        image={roadmap3}
        floatingImg={rightArrow}
        alt="Roadmap"
        id=""
        name="roadmap"
        class="jumbo-section jumbo-left medium-title mt-m7 mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt-3 swap"/>

      <TwoCols 
        lineImg={line2}
        date="Stage Six"
        title="Evolve & Earn with your NFT"
        text="Yep, you read that right, “evolve”. Details will be announced in due time…"
        image={evolve}
        floatingImg={leftArrow}
        alt="Roadmap"
        id=""
        name="roadmap"
        class="jumbo-section jumbo-left medium-title mt-m7 mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt3 "/>
        
        <TwoCols 
        lineImg={line3}
        date="Stage Seven"
        title="PAINT-2-EARN GAME LAUNCHES (BETA)"
        text="Our official P2E game beta launches for holders to enjoy."
        image={roadmap5}
        floatingImg={rightArrow}
        alt="Roadmap"
        id=""
        name="roadmap"
  
        class="jumbo-section jumbo-left medium-title mt-m7 mb-m5 mw-85 mobile-mb-3 left-20 mobile-pt-3 swap"/>
      </Container>
      
      <a name="team" />
      <Container className="team-container mobile-pb-15 enter-paperverse"  style={{position:"relative", zIndex:"3"}}>
     
        <Row>
          <Col xs={12}  className="text-container">
            <h2>
              Enter the Paperverse
            </h2>
            <p></p>

          </Col>

        </Row>
        <Row>
          <Col xs={12}  className="text-container team-container-center">
          <Link to="https://dashboard.paperhandsnft.com/dashboard"> <Button>
            Enter
          </Button></Link>

          </Col>

        </Row>
        {/* <FourCols 
        class1="team-box"
        class2="team-box"
        class3="team-box"
        class4="team-box"
        
        image1={nftdyor}
        name1="NFTDYOR"
        role1="Co-Founder"
     
        image2={flex}
        name2="Flex"
        role2="Community Manager"
        
        image3={drake}
        name3="Drake"
        role3="Artist"
        
        image4={katy}
        name4="Katy"
        role4="Marketing"/> */}

        {/* <FourCols 
        class1="team-box"
        class2="team-box"
        class3="team-box"
        class4="team-box"
        
        image1={saantii}
        name1="Saantii"
        role1="Community Manager"

        image2={brad}
        name2="Brad"
        role2="Full Stack Developer"

        image3={martin}
        name3="Martin"
        role3="Contract Development"

        image4={metal}
        name4="Metal"
        role4="Advisor"/> */}
       
      
         {/* <Row className="partners-row pt-3">
          <Col xs={12} lg={6} className="text-container">
            <h2>
              Partners
            </h2>
            <p></p>

          </Col>

        </Row> */}
        {/* <FourCols 
        class1="team-box"
        class2="team-box"
        class3="team-box"
        class4="display-none"
        image1={metal}
        name1="Metal"
        role1="Advisor"
        image2={metal}
        name2="Metal"
        role2="Advisor"
        image3={metal}
        name3="Metal"
        role3="Advisor"
        
        /> */}
        </Container>

        <a name="faqs" />
   <Container className="faq-container mobile-pt-7" style={{position:"relative", zIndex:"4"}} >
        <Row>
          <Col xs={12} lg={6} className="text-container">
         
            <h2 className="title">
              FAQs
            </h2>

          </Col>

        </Row>
        <FAQs 
        image={faqMan}/>
      </Container>
      <a href="https://raritysniper.com/" style={{display: "none"}}></a>
</Layout>
)

export default IndexPage
